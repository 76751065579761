<template>

  <div class="pbf" v-if="data.role_name">

     <div v-if="edit_data && edit_data.id">
       <n-message-provider>
         <TabEditBrand
          :data="data"
          :edit_data="edit_data"
          />
        </n-message-provider>
     </div>

     <!-- <div v-if="edit_data && edit_data.brandseed">
       <n-message-provider>
         <TabEditBrandSeed
          :data="data"
          :edit_data="edit_data.brandseed"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.brandnutrient">
       <n-message-provider>
         <TabEditBrandNutrient
          :data="data"
          :edit_data="edit_data.brandnutrient"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.brandlamp">
       <n-message-provider>
         <TabEditBrandLamp
          :data="data"
          :edit_data="edit_data.brandlamp"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.brandtent">
       <n-message-provider>
         <TabEditBrandTent
          :data="data"
          :edit_data="edit_data.brandtent"
          />
        </n-message-provider>
     </div> -->

   </div>

</template>

<script setup>

import { NMessageProvider } from 'naive-ui'

import TabEditBrand from '@/pages/brands-manager/components/EditBrand'
// import TabEditBrandSeed from '@/pages/brands-manager/components/EditBrandSeed'
// import TabEditBrandNutrient from '@/pages/brands-manager/components/EditBrandNutrient'
// import TabEditBrandLamp from '@/pages/brands-manager/components/EditBrandLamp'
// import TabEditBrandTent from '@/pages/brands-manager/components/EditBrandTent'

const {$api} = useNuxtApp()

const props = defineProps({
  data: {
    type: Object    
  }
});


const loadData = async () => {
  var response = await $api.getB2BEdit();
  return response;

  // .then(res => {
  //   if(res){
  //     edit_data.value = res
  //     return edit_data
  //   }
  // })
}

const { pending: is_loading,  data: edit_data } = await useLazyAsyncData('edit_data', async () => await loadData())


useHead(useNuxtApp().$head.getBrandManager());

/*export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {     
    TabEditBrandSeed,
    TabEditBrandNutrient,
    TabEditBrandLamp,
    TabEditBrandTent
  },  
  data () {
    return {
      edit_data: Object
    }
  },
  created() {        
    this.loadResults();
  },
  head() { 
    return this.$head.getBrandManager()
  },
  computed: {
  },
  methods: {
    async loadResults(){
      let res = await this.$api.getB2BEdit();
      if(res) 
        this.edit_data = res;
    }
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>

 /* EDIT BRAND */

.two_fld{
  
}

.pr_edit .two_fld>.group50 {
  width: 50%;
}

.pr_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.pr_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.pr_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.pr_edit .nr {
  display: flex;
}

.pr_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.pr_edit .nr .av img {
  width: 100%;
}

.pr_edit .nr .nm {
  width: 250px;
}

.pr_edit .nr .fup {
  position: relative;
}
.pr_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.pr_edit .two_fld>.field {
  width: 100%;
}

.pr_edit .two_fld .select,.pr_edit .two_fld .select select{
  width: 100%!important;
}

.pr_edit .two_fld .button {
  width: 100%!important;
}

.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.field-words{
  width: 100%;
  text-align: right;
}

/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box.background{
  min-height: 200px;
}

.no-padding{
  padding: 0px;
}

.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}

.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos{
  width: 100%;
}

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}



@media (max-width: 768px) {
  .pr_edit .two_fld>.field{
    width:100%;
  }
}

</style>